@import '../../assets/sass/styles.scss';
.winners {
  min-width: 50vw;
  padding: 60px;
  border-radius: 5px;
  h2 {
    color: $white;
    font-size: 3rem;
    font-weight: 800;
    text-align: center;
    padding-bottom: 10px;
    font-family: 'PTSansNarrow', 'PTSans', 'Ubuntu', Arial -apple-system,
      BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Cantarell',
      'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif !important;
  }
  .winners-wrapper {
    margin-top: 0;
    margin-bottom: 0;
    margin-left: 0;
    margin-right: 0;
    min-height: 40vh;
    font-size: 2rem;
    color: $third;

    .column {
      padding: 0;
      text-align: center;
      .prize-name {
        color: white;
        font-size: 1.2rem;
        font-weight: 500;
      }
    }
  }
}

@media screen and (max-width: 769px) {
  .winners {
    h2 {
      font-size: 2rem;
    }
    .winners-wrapper {
      font-size: 1.5rem;
    }
  }
}
