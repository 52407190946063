@import '../../assets/sass/styles.scss';
.Main {
  // background-image: url('../../assets/images/background_2.jpg'),
  //   linear-gradient(
  //     90deg,
  //     rgba(1, 25, 94, 1) 0%,
  //     rgba(2, 32, 104, 1) 40%,
  //     rgba(1, 21, 86, 1) 100%
  //   );
  background-image: url('../../assets/images/background_2_cropped_test.png');
  background-size: 100% auto;
  background-repeat: no-repeat;
  background-position: top right;
  background-color: #14246a;
  .wheat {
    max-height: 70px;
    position: absolute;
    top: 45px;
    right: 85px;
  }

  .banner {
    text-align: center;
    padding: 0 0;
    min-height: 110vh;

    .banner-header {
      max-width: 60vw;
      padding: 40px 0;
      text-align: left;
      .fazer-logo {
        max-height: 95px;
        margin-bottom: 15px;
      }
      h1 {
        padding-left: 50px;
        font-family: 'PTSansNarrow', 'PTSans', 'Ubuntu', Arial -apple-system,
          BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Cantarell',
          'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif !important;
        color: $primary;
        font-weight: 800;
        font-size: 5rem;
        line-height: 1;
        padding-bottom: 20px;

        span {
          display: block;
        }
      }
      h2 {
        line-height: 1;
        padding-bottom: 20px;
        padding-left: 50px;

        color: $primary;
        font-weight: 800;
        font-size: 6.6rem;
        font-family: 'PTSansNarrow', 'PTSans', 'Ubuntu', Arial -apple-system,
          BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Cantarell',
          'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif !important;
      }
      p {
        color: $third;
        font-size: 2rem;
        padding-right: 60px;
        padding-left: 50px;

        font-family: 'PTSansNarrow', 'PTSans', 'Ubuntu', Arial -apple-system,
          BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Cantarell',
          'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif !important;
        span {
          display: block;
        }
      }
      .campaign-period {
        font-family: 'PTSansNarrow', 'PTSans', 'Ubuntu', Arial -apple-system,
          BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Cantarell',
          'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif !important;
        color: $third;
        padding: 20px 0 40px 0;
        font-size: 1.8rem;
        padding-left: 50px;
      }
      .button {
        margin-left: 50px;
      }
    }
    .banner-prize {
      position: absolute;
      right: 0;
      left: -60px;
      margin: auto;
      bottom: -40px;
      max-width: 23vw;
      max-height: 78vh;
    }
  }
  .footer {
    background-color: $background-accent;
    .container {
      text-align: end;
    }
  }
}

/* PC BIG SCREEN */
@media (min-width: 1441px) {
  .Main {
    .banner {
      .banner-header {
        h1 {
          font-size: 8rem;
        }

        p {
          font-size: 2.5rem;
        }
      }
    }
  }
}

@media screen and (max-width: 769px) {
  .Main {
    background-image: url('../../assets/images/mobile_bg.png');
    background-size: contain;
    background-position: top center;
    .wheat {
      max-height: 40px;
      top: 15px;
      right: 15px;
    }
    .banner {
      min-height: auto;
      padding-bottom: 15vh;
      .columns {
        display: flex;
        flex-direction: row;
        padding: 48vh 40px 0 40px;
        .column {
          &.banner-header {
            padding: initial;
            max-width: 100vw;
            h1 {
              font-size: 1.5rem;
              line-height: 1;
              padding-left: initial;
            }
            h2 {
              padding-left: initial;

              font-size: 2rem;
              line-height: 1;
              padding-bottom: 5px;
            }
            p {
              padding-left: initial;

              font-size: 0.9rem;
              padding-right: 0;
              span {
                display: initial;
              }
            }
            .campaign-period {
              font-size: 0.8rem;
              padding: 10px 0 20px 0;
            }
            .button {
              margin-left: initial;
            }
            .fazer-logo {
              position: absolute;
              top: 25px;
              max-height: 60px;
            }
          }
          &.is-half-mobile {
            padding: 0;
          }
          .banner-prize {
            position: static;
            max-width: 100%;
            height: auto;
          }
          .button {
            font-size: 0.9rem;
            padding: 15px 30px 15px 30px;
          }
        }
      }
      .banner-prize {
        max-width: 100vw;
      }
    }
    .prizes {
      padding: 2rem;
    }
  }
}
