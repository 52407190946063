@import './assets/sass/styles.scss';

.App {
  button.button,
  a.button {
    background-color: $secondary;
    border: none;
    padding: 20px 70px;
    color: $primary;
    border-radius: 0;
    font-size: 1.2rem;
    cursor: pointer;
    font-family: 'PTSans', 'Ubuntu', Arial -apple-system, BlinkMacSystemFont,
      'Segoe UI', 'Roboto', 'Oxygen', 'Cantarell', 'Fira Sans', 'Droid Sans',
      'Helvetica Neue', sans-serif !important;
    // -webkit-box-shadow: 0px 4px 0px 0px rgba(0, 0, 0, 0.3);
    // -moz-box-shadow: 0px 4px 0px 0px rgba(0, 0, 0, 0.3);
    // box-shadow: 0px 4px 0px 0px rgba(0, 0, 0, 0.3);
    transition: all 0.2s linear;
    &:hover {
      // -webkit-box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.3);
      // -moz-box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.3);
      // box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.3);
      transform: translate(0, 4px);
    }
  }
  a.link {
    color: $primary;
    text-decoration: underline;
    cursor: pointer;
    display: inline-block;
    &.padded {
      padding-right: 3px;
    }
    &:hover {
      text-decoration: none;
    }
  }
}
