@import '../../assets/sass/styles.scss';

.Register {
  margin: 5rem;
  padding-top: 8rem;
  .container {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    min-height: 80vh;
    &.thanks {
      h3 {
        color: $primary;
        font-size: 3rem;
        font-weight: 800;
        font-family: 'PTSansNarrow', 'PTSans', 'Ubuntu', Arial -apple-system,
          BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Cantarell',
          'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif !important;
      }
      span {
        color: $third;
        font-size: 1.2rem;
      }
    }
    a {
      color: $third;
    }
    h2 {
      font-size: 4rem;
      font-weight: 800;
      margin-bottom: 5px;
      color: $primary;
      font-family: 'PTSansNarrow', 'PTSans', 'Ubuntu', Arial -apple-system,
        BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Cantarell',
        'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif !important;

      // text-shadow: 0px 4px 0px rgba(0, 0, 0, 0.31);
    }
    .register-notice {
      color: $third;
      // text-shadow: 0px 2px 0px rgba(0, 0, 0, 0.31);
      font-size: 1.2rem;
      margin-bottom: 25px;
    }
    .campaign-over-wrapper {
      text-align: center;
      color: white;
      font-size: 1.2rem;
      span {
        display: block;
      }
    }
    #register-form {
      .help.is-danger {
        // color: $primary;
        color: red;
      }
      .field {
        min-width: 600px;
        &.checkbox-field {
          text-align: center;
          margin-top: 40px;
        }
        input {
          color: $secondary;
          // text-shadow: 0px 2px 0px rgba(0, 0, 0, 0.31);
          border: none;
          padding: 25px 15px;
          // background-color: transparent;
          // -webkit-box-shadow: 0px 2px 0px 0px rgba(0, 0, 0, 0.3);
          // -moz-box-shadow: 0px 2px 0px 0px rgba(0, 0, 0, 0.3);
          // box-shadow: 0px 2px 0px 0px rgba(0, 0, 0, 0.3);
          border-radius: 0;
          &::placeholder {
            /* Chrome, Firefox, Opera, Safari 10.1+ */
            color: $secondary;
            opacity: 1; /* Firefox */
          }

          &:-ms-input-placeholder {
            /* Internet Explorer 10-11 */
            color: $secondary;
          }

          &::-ms-input-placeholder {
            /* Microsoft Edge */
            color: $secondary;
          }
        }
        .checkbox {
          color: $third;
          // text-shadow: 0px 2px 0px rgba(0, 0, 0, 0.31);

          input {
            margin-right: 15px;
            text-shadow: none;
            box-shadow: none;
            padding: initial;
          }
        }
      }
      .submit-btn-wrapper {
        text-align: center;

        .button {
          margin: 20px 0;
          padding: 20px 70px;
        }
      }
    }
  }
}

@media screen and (max-width: 769px) {
  .Register {
    margin: 0;
    padding-top: 0;
    padding-left: 40px;
    padding-right: 40px;

    .container {
      h2 {
        font-size: 2rem;
      }
      .register-notice {
        font-size: 0.7rem;
      }
      #register-form {
        .field {
          min-width: 85vw;
        }
        .button {
          font-size: 0.9rem;
          padding: 15px 30px 15px 30px;
        }
      }
    }
  }
}
