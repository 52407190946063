@import '../../assets/sass/styles.scss';

.rules {
  .rules-wrapper {
    padding: 30px 60px;
    h2 {
      text-align: center;
      font-size: 3rem;
      color: $primary;
      font-weight: 800;
      margin-bottom: 30px;
      font-family: 'PTSansNarrow', 'PTSans', 'Ubuntu', Arial -apple-system,
        BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Cantarell',
        'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif !important;
    }
  }
}

ol {
  color: $primary;
  text-shadow: 0px 2px 0px rgba(0, 0, 0, 0.31);
  list-style: none;
  counter-reset: item;
  font-family: 'Ubuntu', Arial -apple-system, BlinkMacSystemFont, 'Segoe UI',
    'Roboto', 'Oxygen', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif !important;
  > li {
    counter-increment: item;
    display: table;
    &:before {
      padding-right: 10px;
      content: counters(item, '.') ' ';
      display: table-cell;
    }
  }
  li {
    margin: 10px 0;
    line-height: 2;
  }
  ol {
    padding-left: 20px;
  }
  ul {
    padding-left: 20px;
    list-style: none;
  }
  // ul li {
  //   margin-left: 20px;
  // }
  .bold {
    font-weight: 600;
  }
  table {
    border-collapse: collapse;
    td,
    th {
      padding: 3px;
    }
  }
  table,
  th,
  td {
    border: 1px solid black;
  }
}

@media screen and (max-width: 769px) {
  .rules {
    .rules-wrapper {
      padding: 20px 10px;
      h2 {
        font-size: 2rem;
      }
    }
  }
  ol {
    list-style: none;
    counter-reset: item;
    font-size: 0.9rem;
    ol {
      padding-left: 10px;
    }
    ul li {
      margin-left: 10px;
    }
  }
}
