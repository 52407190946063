// $primary: #fc6ac2;
// $secondary: white;
// $accent: #fa32aa;
// $background-color: #fceef6;
// $background-accent: #ffdff1;
// $button-bg-color: #f86cc0;
// $button-bg-hover-color: #fc4ab5;
// $text-color: rgba(87, 87, 87, 0.9);
$primary: white;
$secondary: #b99553;
$third: #f3d7a5;
$accent: #0d216a;
$background-color: #14226d;
$background-accent: #d3d3d3;
$secondary-bg-color: #ffde01;
$button-bg-color: #fff204;
$button-bg-hover-color: #fff204a6;
$text-color: rgba(87, 87, 87, 0.9);
